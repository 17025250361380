import React from 'react';

const Cert8 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO ISO 22301:2019 Certification (Business Continuity Management System)</h2>
                            <p>Universal Cert is a pioneer certification body to provide ISO 22301:2019 (BCMS) Certification.</p>
                            <p>ISO 22301 is the Business Continuity Management System (BCMS) standard. The goal of Business Continuity Management System is to ensure that your company is protected from the risks associated with 
                                downtime due to unexpected disruptions or disasters.</p>
                            <p>Disruptive incidents to your business lead to income loss, data risk breakdowns and 
                                lack of success in providing normal client services as per service level agreements (SLAs). 
                                ISO 22301 Business Continuity Management Systems certification will 
                                guide a company ineffectively planning for such eventualities.</p>
                                    <h2>Why is ISO 22301 important for your business?</h2>
                            
                            <p> As the market constantly grows, management systems like ISO 22301 can mean the difference in expanding your client base as well as enabling 
                                you to understand the impact of risks facing your organization.
                            </p>
                            <p> With ISO 22301 Business Continuity Management Standard you will understand and
                                 prioritize the threats to your business. ISO 22301points out the requirements for a management system to 
                                protect against disruptions and ensure your business recovers from disruptive incidents.
                            </p>
                            <p> ISO 22301 Business Continuity Management certification can protect your 
                                company against the damage resulting from expired deadlines, leakages of data, operational and IT outages, industrial activities,
                                 clients being disappointed or direct financial loss due to disruptive incidents.</p>
                            
                            <h2>
                            Benefits of ISO 22301:2019 Certification
                            </h2>
                            <ul>
                                <li>Appropriate and agile contingencies.</li>
                                <li>Ability to respond effectively in the event of disruptions.</li>
                                <li>Better identification and control of business risks.</li>
                                <li>Reduces impact and frequency of business disruptions.</li>
                                <li>Establishment of a better reputation and credibility.</li>
                                <li>Upgrade in your performance by having strong commitment in management.</li>
                                <li>High levels of service quality when bidding for international contracts or expanding locally to accommodate new business.</li>
                                <li>Satisfaction of clients.</li>
                                <li>Reduction of costs via mitigating the impact of disruptions.</li>
                                <li>Improvement of your market potential domestically and internationally.</li>
                                <li>Meaningful relationships with your staff, customers and suppliers.</li>
                                <li>Legal compliance with better understanding of the relevant statutory and regulatory requirements.</li>
                                </ul>
                                <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert8