import React from 'react';

const Cert3 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO 45001:2018 Certification (Environmental Management System)</h2>
                            <h3>Universal Cert is a Leading company for ISO 45001 Certification which provides documentation, auditing, training, consultancy &certification.</h3>
                            <p>This standard specifies requirements for an occupational health and safety (OH&S)
                                 management system, and gives guidance for itsuse, to enable organizations to provide safe 
                                 and healthy workplaces by preventing work-related injury and ill health, as well as byproactively 
                                 improving its OH&S performance. This standard is applicable to any organization that wishes 
                                 to establish, implement andmaintain an OH&S management system to improve occupational health
                                  and safety, eliminate hazards and minimize OH&S risks(including system deficiencies), 
                                  take advantage of OH&S opportunities,
                                 and address OH&S management system nonconformitiesassociated with its activities.</p>
                                <ul>
                                    <li> Continual improvement of OH&S performance.</li>
                                    <li> Fulfillment of legal requirements and other requirements.</li>
                                    <li> Achievement of OH&S objectives.</li>
                                </ul>
                               
                            
                            <h2>
                            Advantage of ISO 45001 Certification
                            </h2>
                            <ul>
                            <li>Demonstrate compliance with current and future statutory and regulatory requirements.</li>
                            <li>Increase leadership involvement and engagement of employees.</li>
                            <li>Improve company reputation and the confidence of stakeholders through strategic communication.</li>
                            <li>Achieve strategic business aims by incorporating environmental issues into business management.</li>
                            <li>Provide a competitive and financial advantage through improved efficiencies and reduced costs.</li>
                            <li>Encourage better environmental performance of suppliers by integrating them into the organization’s business systems.</li>
                                </ul>
                        </div>
                        <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert3