import React, { useEffect,  } from 'react';


const Home = (props) => {
    const scrollWithHash = props.scrollWithHash;
    useEffect(() => {
        scrollWithHash();
    }, []);
    return (
        <>
            <section id="hero" className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up">Making ISO Certification Easier.</h1>
                            <h2 data-aos="fade-up">Certification in Hours not in Days</h2>
                            <h2 data-aos="fade-up" data-aos-delay="400">Hassle Free Process to achieve all standards of ISO including ISO 9001,
                             ISO 14001, ISO 45001, ISO 27001, ISO 27701, GDPR, PCI-DSS, and SOC 1/2/3.</h2>

                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            {/* <img src="assets/img/hero-img.png" className="img-fluid" alt="" /> */}
                            
                            <form className='' >
                            <div className='d-flex flex-column p-2 gap-2 mx-5 '>
                                <input type='text' placeholder='Name' className='p-2 w-75' />
                                <input type='text' placeholder='Phone ' className='p-2 w-75' />
                                <input type='text' placeholder='Email'className='p-2 w-75' />
                                <textarea type='textarea' placeholder='Message'className='p-2 w-75'/>
                                <button type='button' className='mt-4 btn btn-warning w-75'>Contact Now!</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>

            </section>

            <main id="main">

                <section id="services" className="values">
                    <div className="container" data-aos="fade-up">
                    <div className='d-flex gap-4 col-lg-12 flex-wrap'>
                    <div className='col-sm-2'>
                    <div className='card bg-primary p-3 '> 
                        <div>
                            <img src='assets/img/certificate.png'  className= ' w-50 ' alt=''/>
                         </div>
                         <div>
        <span className="text-light">ISO</span>
        </div>
        <span className="text-light"> Certificate Services</span>
    </div>
    </div>
    <div className='col-sm-2'>
    <div className='card bg-warning p-3'> 
        <div>
        <img src='assets/img/human.png'  className= ' w-50 ' alt=''/>
        </div>
        <div>
        <span className=" text-light"></span>
        </div>
        <span className=" text-light">Consultancy Services</span>    
    </div>
    </div>
    <div className='col-sm-2'>
    <div className='card bg-primary p-3'> 
        <div>
        <img src='assets/img/training.png'  className= ' w-50 ' alt=''/>
        </div>
        <div>
        <span className="text-light"> ISO</span>
        </div>
        <span className="text-light"> Training Services</span>
    </div>
    </div>
    <div className='col-sm-2'>
    <div className='card bg-warning p-3'> 
        <div>
        <img src='assets/img/cybersec.png'  className= ' w-50 ' alt=''/>
        </div>
        <div>
        <span className=" text-light">Cyber</span>
        <span className=" text-light"> Security</span>    
        </div>
        <span className="text-light"> Services</span>
    </div>
    </div>
    <div className='col-sm-2'>
    <div className='card bg-primary p-3'> 
        <div>
        <img src='assets/img/compliance.png'  className= ' w-50 ' alt=''/>
        </div>
        <div>
        <span className=" text-light"> Compliance</span>
        </div>
        <span className=" text-light">Services</span>    
    </div>
    </div>
    </div>
</div>

                    {/* <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div id="webdesign" className="col-lg-4 " data-aos="fade-up" data-aos-delay="200">
                                <div className="box">
                                    
                                </div>
                            </div>

                            <div id="mobileapp" className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
                                <div className="box">
                                    <img src="assets/img/values-2.png" className="img-fluid" alt="" />
                                    <h3>Mobile Application</h3>
                                    <p>
                                    Mobile applications	are	the	keystone for many businesses in	the	digital	age.	
                                    Apps running on	smart devices give customers greater access	to	company related	resources	and	gives	companies	the	chance	to	acquire	more	business	
                                    opportunities.
                                    </p>
                                </div>
                            </div>

                            <div id="hosting" className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
                                <div className="box">
                                    <img src="assets/img/values-3.png" className="img-fluid" alt="" />
                                    <h3>Hosting</h3>
                                    <p>
                                    We provide shared or dedicated hosting of one or more services for their customers.
                                    Usually used for hosting websites, a web hosting service can also be used to host company email, files, games and other content
                                        
                                    </p>
                                </div>
                            </div>

                            <div id="webapp" className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="800">
                                <div className="box">
                                    <img src="assets/img/values-4.png" className="img-fluid" alt="" />
                                    <h3>Web Application</h3>
                                    <p>
                                    Eagle Tech’s best-in-class focus on user experience will have potential	
                                    customers clamoring to your business’s page	or e-commerce shop
                                    We’ll build your site to optimize your target customer’s	
                                    experience	
                                    </p>
                                </div>
                            </div>

                            <div id="ecommerce" className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="1000">
                                <div className="box">
                                    <img src="assets/img/values-5.png" className="img-fluid" alt="" />
                                    <h3>E-commerce</h3>
                                    <p>
                                        We provide selling and buying anything online, come and get your own websites of e-commerce and we will help you in advitisement
                                        
                                    </p>
                                </div>
                            </div>

                            <div id="cbsec" className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="1200">
                                <div className="box">
                                    <img src="assets/img/values-6.png" className="img-fluid" alt="" />
                                    <h3>Cyber Security</h3>
                                    <p>
                                    We work with our clients to provide best technology designed to protect their
                                     networks programs and devices from cyber attack.
                                     
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div> */}

                </section>

                <section id="services" className="values">
                    <div className="container" data-aos="fade-up">
                    <header className="section-header">
                            <p className=''>Benefits of ISO Certificate</p>
                        </header>
                        <section id="hero" className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row">
                    <div className="col-lg-6 hero-img" data-aos="" data-aos-delay="200">
                            <img src="assets/img/danny.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="" data-aos-delay="200">
                            <img src="assets/img/pic-1.png" className="img-fluid" alt="" />
                        </div>
                        
                       
                    </div>
                </div>

            </section>
                    </div>
                    
                </section>

                {/* <section id="services" className="services">

                    <div className="container" data-aos="fade-up">

                        <header className="section-header">
                            <p>Achieved Production</p>
                        </header>

                        <div className="row gy-4">

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                                <div className="service-box blue">
                                    <i className="ri-discuss-line icon"></i>
                                    <h3>Nesciunt Mete</h3>
                                    <p>Provident nihil minus qui consequatur non omnis maiores. Eos accusantium minus dolores iure perferendis tempore et consequatur.</p>
                                    <Link to="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                                <div className="service-box orange">
                                    <i className="ri-discuss-line icon"></i>
                                    <h3>Eosle Commodi</h3>
                                    <p>Ut autem aut autem non a. Sint sint sit facilis nam iusto sint. Libero corrupti neque eum hic non ut nesciunt dolorem.</p>
                                    <Link to="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                                <div className="service-box green">
                                    <i className="ri-discuss-line icon"></i>
                                    <h3>Ledo Markt</h3>
                                    <p>Ut excepturi voluptatem nisi sed. Quidem fuga consequatur. Minus ea aut. Vel qui id voluptas adipisci eos earum corrupti.</p>
                                    <Link to="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                                <div className="service-box red">
                                    <i className="ri-discuss-line icon"></i>
                                    <h3>Asperiores Commodi</h3>
                                    <p>Non et temporibus minus omnis sed dolor esse consequatur. Cupiditate sed error ea fuga sit provident adipisci neque.</p>
                                    <Link to="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                                <div className="service-box purple">
                                    <i className="ri-discuss-line icon"></i>
                                    <h3>Velit Doloremque.</h3>
                                    <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                                    <Link to="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                                <div className="service-box pink">
                                    <i className="ri-discuss-line icon"></i>
                                    <h3>Dolori Architecto</h3>
                                    <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
                                    <Link to="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></Link>
                                </div>
                            </div>

                        </div>

                    </div>

                </section> */}

            </main>
        </>
    );
}

export default Home