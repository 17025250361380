import React from 'react';

const Cert5 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO 22000:2018 Certification (Food Safety Management System)</h2>
                            <p>Universal Cert is a pioneer certification body to provide ISO 22000:2018 (FSMS) Certification.</p>
                                <p> <b>ISO 22000:2018 </b>is the recently modified International Food Safety standard, intended to fit on a worldwide scale the prerequisites for 
                                    Food Safety Management for organizations inside the evolved way of life.</p>
                                <p><b>ISO 22000</b> Certification consolidates and enhancements the center components of ISO 9001 and HACCP to give a powerful structure to the advancement, execution, monitorization and consistent improvement of an archived Food Safety
                                     Management System (FSMS)inside the setting of the association’s general business risks.</p>
                                <p><b>ISO 22000 </b> Certification is expected for associations looking to set up an increasingly focused, reasonable and coordinated FSMS than is regularly legally necessary. The standard requires meeting any 
                                    relevant food safety related statutory and administrative criteria into its food safety system.</p>
                                
                                    <h2>What is ISO 22000:2018 ?</h2>
                            
                            <p> <b>ISO 22000 </b> is a International standard for Food Safety Management System (FSMS) that can be applied to any organization in the food chain. Certification of ISO 22000 allows a company to 
                                show their customers that they have a food safety management system implemented.
                            </p>
                            
                            <h2>
                            6 Key Benefits of ISO 22000:2018 Certification
                            </h2>
                            <ol>
                            <li> Helps to identify and control food safety risk.</li>

                            <li> Reduce risk of food safety.</li>

                            <li>Increase market growth and gain customer confidence.</li>

                            <li> Fully compatible with HACCP.</li>

                            <li> Shows your commitment to food safety culture.</li>

                            <li> Reduce the need of frequent food safety audits in organisation.</li>
                                </ol>
                                <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert5