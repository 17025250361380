import React from 'react';

const Cert1 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                             {/* <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="400" style={{margin: "auto"}}>
                                <img src="assets/img/about.png" className="img-fluid" alt="Eagle Technology" />
                            </div>

                            <p>
                                completing	projects in	verticals ranging	from oil and gas	to	
                                healthcare to CPG and beyond. In addition to technological	acumen,	our	
                                management team	is	truly positioned to	set	clients	up	for	success	with their	
                                wide array of business experiences	in	both startups and	established	corporate	
                                cultures.
                            </p> */}
                            <h2>ISO 9001:2015 Certification (Quality Management System)</h2>
                            <p>ISO 9001:2015 is an internationally recognized standard for Quality Management Systems (QMS).
                                 ISO is the abbreviation of International Organization for Standard. It provides a framework and principles 
                                 for your company to ensure that the common sense approach to running your business in a consistent manner 
                                 to achieve customer satisfaction. ISO 9001:2015 require an organization to develop a quality management 
                                 system that fits the product & Process requirement as well as regulatory requirement. However, the best returns come 
                                 from companies who are ready 
                                to take it throughout the organization rather than individual sites, departments or regions.</p>
                
                            <h2> Advantage of ISO 9001 Certification</h2> 
                                <ul>
                                    <li>Provides a systematic and visible way to the continuous improvement of performance.</li>
                                    <li>Edges over competitors who do not have accreditation.</li>
                                    <li>Edges over competitors who do not have accreditation.</li>
                                    <li>Working structure for the organization to achieve the desired results.</li>
                                    <li>Improve the brand value of the organization.</li>
                                    <li>Helps in increasing efficiency in the organization.</li>
                                    <li>Improving the coherence of higher education service and product quality levels of customer satisfaction.</li>
                                    <li>Improved productivity, improved customer perception and cost savings.</li>
                                    <li>Improved communication effectiveness, morale and competitive advantage with job satisfaction increased marketing and sales opportunities.</li>
                                </ul>
                               
                            
                            <h2>
                            7 Principal of Management
                            </h2>
                            <ol>
                                <li> – Customer Focus.</li>
                                <li> – Leadership.</li>
                                <li> – Engagement of People.</li>
                                <li> – Process Approach.</li>
                                <li> – Improvement.</li>
                                <li> – Evidence-based Decision Making.</li>
                                <li> – Relationship Management.</li>

                            </ol>

                           
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert1