import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
    return (
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div
                    className="row"
                    style={{ justifyContent: "center", textAlign: "center" }}
                >
                    <div className=" d-flex flex-column justify-content-center">
                        <h1 data-aos="fade-up">404</h1>
                        <h2 data-aos="fade-up" data-aos-delay="200">
                            OOPS !
                        </h2>
                        <h2 data-aos="fade-up" data-aos-delay="400">
                            Page not found.
                        </h2>
                        <div
                            data-aos="fade-up"
                            data-aos-delay="600"
                            style={{ width: "fit-content", margin: "60px auto" }}
                        >
                            <div className="text-center text-lg-start">
                                <Link
                                    to="/"
                                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                                >
                                    <span style={{ padding: "10px" }}>Go to Home Page</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Page404;
