import React from 'react';

const Cert4 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO 20000:2018 Certification (Information Technology Service Management System)</h2>
                            <p>ISO 20000 specifies the basis for a IT service management system (ITSMS). However, 
                                the structure of this document is not unique to this type of system. In fact, 
                                the ISO 20000 standard was changed to include the shared ISO high level structure (Annex SL), 
                                which helps to assure 
                                the usefulness and interconnectivity of ISO management system standards.</p>
                                <p>A SMS supports the management of the service lifecycle (planning, design, 
                                    transition, delivery, and improvement of services) by incorporating the context of the organization, 
                                    leadership, planning, support and operation of the SMS and its processes, and performance evaluation.
                                     This can provide a greater understanding of the customers’ needs and offer a better value for those customers in delivering services
                                    , giving ongoing visibility, and allowing for continual improvement.</p>
                                <p>ISO 20000 covers guidelines for an organization to establish, implement, maintain, 
                                        and continually improve a service management system. This international standard document has a wide range of use,
                                         with potential users including: a customer seeking services and desiring assurance for the quality of those services; 
                                         a customer needing a consistent approach to the service lifecycle; an organization needing to demonstrate its capability for and/or improve the planning, 
                                         design, transition, delivery, and improvement of services; an organization desiring to monitor, measure, and review its SMS; an organization or other party 
                                         performing conformity assessments against the requirements specified in this document; 
                                        and a provider of training or advice in the field of service management.</p>
                                <p>ISO 20000 covers guidelines for an organization to establish, implement, maintain, 
                                        and continually improve a service management system. This international standard document has a wide range of use,
                                         with potential users including: a customer seeking services and desiring assurance for the quality of those services; 
                                         a customer needing a consistent approach to the service lifecycle; an organization needing to demonstrate its capability for and/or improve the planning, 
                                         design, transition, delivery, and improvement of services; an organization desiring to monitor, measure, and review its SMS; an organization or other party 
                                         performing conformity assessments against the requirements specified in this document; 
                                        and a provider of training or advice in the field of service management.</p>
                                <p>While ISO 20000 has historically been used for IT services, 
                                    it is increasingly being applied to other services to improve business processes and 
                                    decision making. In fact, some of the changes that distinguish ISO 20000 from the previous edition of 
                                    this standard—the second edition that came out in 2011—are focused on updating the document to better 
                                    meet market trends. However, these changes and 
                                    the inclusion of the high-level structure are by no means the only alterations made to ISO 20000.</p>
                                
                               
                            
                            <h2>
                            6 Key Benefits of ISO 20000-1:2018 Certification
                            </h2>
                            <ol>
                            <li> Offers competitive and high quality of service.</li>

                            <li> Gateway to IT Sector Services.</li>

                            <li> Assures clients that their service requirements will be fulfilled.</li>

                            <li> Enforces a measurable level of effectiveness and a culture of continual 
                                improvement by enabling service providers to monitor, measure and review their service management processes and services.</li>

                            <li> Down the costs of conformance to a multitude of laws and standards.</li>

                            <li> Helps leverage ITIL practices to optimise resources and processes.</li>
                                </ol>
                            <h2>ISO 20000 vs ITIL</h2>
                            <p>
                            <p>ISO 20000 and ITIL are related to each other.</p>
                                ITIL provides advice on ITSM best practices, including options that may be adopted and adapted by organizations 
                                according to business need, local circumstances and the maturity of the service provider.
                            </p>
                            <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                        </div>
                        {/* <div className='w-full bg-primary h-200 mt-3'></div> */}
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert4