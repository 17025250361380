import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/home';
import Footer from './components/cards/footer';
import Navbar from './components/cards/navbar';
import About from './components/pages/about';
import ContactUs from './components/pages/contact-us';
import Page404 from './components/pages/page404';
import Team from './components/pages/team';
import Cert1 from './components/certificates/iso-9001';
import Cert2 from './components/certificates/iso-14001';
import Cert3 from './components/certificates/iso-45001';
import Cert4 from './components/certificates/iso-20000';
import Cert5 from './components/certificates/iso-22000';
import Cert6 from './components/certificates/iso-27001';
import Cert7 from './components/certificates/iso-27701';
import Cert8 from './components/certificates/iso-22301';
import Cert9 from './components/certificates/iso-13485';
import Cert10 from './components/certificates/iso-50001';


function App() {
	const scrollWithHash =() => {
		if (window.location.hash) {
			if (document.querySelector(window.location.hash)) {
				let header = document.getElementById('header');
				let offset = header.offsetHeight;
				let elementPos = document.querySelector(window.location.hash).offsetTop;
				if (!header.classList.contains('header-scrolled')) {
					offset -= 10;
				}

				window.scrollTo({
					top: elementPos - offset,
					behavior: 'smooth'
				});
			}
		}
	}

	useEffect(() => {
		var c = Array.from(document.getElementsByTagName('a'));
		c.forEach((element) => {
			element.addEventListener('click', () => {
				window.scroll(0, 0);
			});
		});

		const script = document.createElement('script');
		script.src = "/assets/js/main.js";
		script.async = true;

		document.body.appendChild(script);
		scrollWithHash();

		return () => {
			document.body.removeChild(script);
		}
	}, []);
  return (
	  	<>
	  	<Router>
			<Navbar />

			<Routes>
				<Route path='' exact element={<Home scrollWithHash={scrollWithHash}/>} />
				<Route path='about' exact element={<About />} />
				<Route path='team' exact element={<Team scrollWithHash={scrollWithHash}/>} />
				<Route path='contact-us' exact element={<ContactUs />} />
				<Route path='iso-9001' exact element={<Cert1 />} />
				<Route path='iso-14001' exact element={<Cert2 />} />
				<Route path='iso-45001' exact element={<Cert3 />} />
				<Route path='iso-20000' exact element={<Cert4 />} />
				<Route path='iso-22000' exact element={<Cert5 />} />
				<Route path='iso-27001' exact element={<Cert6 />} />
				<Route path='iso-27701' exact element={<Cert7 />} />
				<Route path='iso-22301' exact element={<Cert8 />} />
				<Route path='iso-13485' exact element={<Cert9 />} />
				<Route path='iso-50001' exact element={<Cert10 />} />
				


				<Route path='*' exact element={<Page404 />} />
			</Routes>

			<Footer />
		  </Router>
	  	</>
  	);
}

export default App;
