import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ContactUs = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [error, setError] = useState(null);
    const [isSent, setIsSent] = useState(false);
    const [mssg, setMssg] = useState(null);

    const submitHandler = async(event) => {
        setIsSent(true);
        event.preventDefault();
        const res = await fetch("https://sheet.best/api/sheets/1fdf2dd8-758b-47c2-b567-313e7cda5929", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name, email, message})
        });

        if (res.status === 200) {
            setMssg("Your message has been sent. Thank you!");
        }
        else {
            setError("Fail to sent message. Try again!");
        }
        event.target.reset();
        setIsSent(false);
    };
    return (
        <section id="contact" className="contact">

            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <br />
                    <br />
                    <p>Contact Us</p>
                </header>

                <div className="row gy-4">

                    <div className="col-lg-6">

                        <div className="row gy-4">
                            <div className="col-md-6">
                                <div className="info-box">
                                    <Link onClick={() => window.open("https://www.google.com/maps/place/Rubangura+Plaza/@-1.9159633,30.0780774")} to="">
                                        <i className="bi bi-geo-alt"></i>
                                    </Link>
                                    <h3>Address</h3>
                                    <p>Rubangura House <br /> 2nd Floor Room 203,<br />KN 2 St, Kigali, Kigali Rwanda</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box">
                                    <Link to="" onClick={() => window.open("tel:+250 785395473")}>
                                        <i className="bi bi-telephone"></i>
                                    </Link>
                                    <h3>Call Us</h3>
                                    <p> +250 783 875 133<br /></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box">
                                    <Link to="" onClick={() => window.open("mailto:info@universal-cert.com")}>
                                        <i className="bi bi-envelope"></i>
                                    </Link>
                                    <h3>Email Us</h3>
                                    <p>info@universal-cert.com</p><br />
                            
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box">
                                    <i className="bi bi-clock"></i>
                                    <h3>Open Hours</h3>
                                    <p>Monday - Friday<br />8:00AM - 06:00PM</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6">
                        <form  className="php-email-form" onSubmit={(event) => submitHandler(event)}>
                            <div className="row gy-4">

                                <div className="col-md-6">
                                    <input type="text" name="name" className="form-control" placeholder="Your Name" onChange={(event) => setName(event.target.value)} required />
                                </div>

                                <div className="col-md-6 ">
                                    <input type="email" className="form-control" name="email" placeholder="Your Email" onChange={(event) => setEmail(event.target.value)} required />
                                </div>

                                <div className="col-md-12">
                                    <textarea className="form-control" name="message" rows="6" placeholder="Message" onChange={(event) => setMessage(event.target.value)} required></textarea>
                                </div>

                                <div className="col-md-12 text-center">
                                    {mssg ? <div className="sent-message" style={{display: 'block'}}>{mssg}</div> : <></>}
                                    {error ? <div className="error-message" style={{display: 'block'}}></div> : <></>}
                                    {isSent ? <div className="loading" style={{display: "block"}}>Loading</div> : <button type="submit">Send Message</button>}
                                    
                                </div>

                            </div>
                        </form>

                    </div>

                </div>

            </div>

        </section>
    )
}

export default ContactUs