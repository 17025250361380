import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Team = (props) => {
    const scrollWithHash = props.scrollWithHash;
    useEffect(() => {
        scrollWithHash();
    }, []);
    return (
        <section id="team" className="team">

            <div className="container" data-aos="fade-up">

                <header className="section-header">
                    <br />
                    <br />
                    <p>Working team</p>
                </header>

                <div className="row gy-4">

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                        <div className="member">
                            <div className="member-img">
                                <img src="assets/img/team/blop.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                    <Link to="#" onClick={() => window.open("https://web.facebook.com/profile.php?id=100006294558345")}><i className="bi bi-facebook"></i></Link>
                                    <Link to="#" onClick={() => window.open("https://www.instagram.com/b_fabrice70")}><i className="bi bi-instagram"></i></Link>
                                    <Link to="#" onClick={() => window.open("https://www.linkedin.com/in/fabrice-bizimana-8593321b9")}><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>Bizimana Fabrice</h4>
                                <span>software Developer</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                        <div className="member">
                            <div className="member-img">
                                <img src="assets/img/team/chris.png" className="img-fluid" alt="" />
                                <div className="social">
                                    <Link to="#" onClick={() => window.open("https://twitter.com/yubahwe_")}><i className="bi bi-twitter"></i></Link>
                                    <Link to="#" onClick={() => window.open("https://web.facebook.com/shemayubahwe.christian")}><i className="bi bi-facebook"></i></Link>
                                    <Link to="#" onClick={() => window.open("https://www.instagram.com/yubahwe_")}><i className="bi bi-instagram"></i></Link>
                                    <Link to="#" onClick={() => window.open("https://www.linkedin.com/in/yubahwe-shema-christian-b1714222b")}><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>YUBAHWE S Christian</h4>
                                <span>software Developer</span>   
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                        <div className="member">
                            <div className="member-img">
                                <img src="assets/img/team/tro.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                <Link to="#" onClick={() => window.open()}><i className="bi bi-twitter"></i></Link>
                                <Link to="#" onClick={() => window.open("https://www.facebook.com/profile.php?id=100008436885107")}><i className="bi bi-facebook"></i></Link>
                                <Link to="#" onClick={() => window.open("https://twitter.com/Theprie59583402")}><i className="bi bi-twiter"></i></Link>
                                <Link to="#" onClick={() => window.open("https://www.instagram.com/trophime250")}><i className="bi bi-instagram"></i></Link>
                                <Link to="#" onClick={() => window.open("https://www.linkedin.com/in/trophime-karasisi-1186b622b")}><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>KARASISI Trophime</h4>
                                <span>Web Designer</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
                        <div className="member">
                            <div className="member-img">
                                <img src="assets/img/team/toto.jpg" className="img-fluid" alt="" />
                                <div className="social">
                                        <Link to="#" onClick={() => window.open("https://twitter.com/rugero__")}><i className="bi bi-twitter"></i></Link>
                                        <Link to="#" onClick={() => window.open("https://www.instagram.com/jama_w_jah/")}><i className="bi bi-instagram"></i></Link>
                                        <Link to="#" onClick={() => window.open("https://www.linkedin.com/in/byishimo-teto-29642a17a")}><i className="bi bi-linkedin"></i></Link>
                                </div>
                            </div>
                            <div className="member-info">
                                <h4>BYISHIMO TETO Joseph</h4>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </section>

    )
}

export default Team