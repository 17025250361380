import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">

                        <div className="col-lg-4 col-md-12 footer-info">
                            <Link title='Universal-cert.' to="/" className="logo d-flex align-items-center">
                                <img src="/assets/img/logo.png" alt="Universal-cert." style={{paddingBottom: ""}}/>
                                <span>Universal Cert.</span>
                            </Link>
                            
                            <div className="social-links mt-3">
                                <Link to="#" className="twitter"><i className="bi bi-twitter"></i></Link>
                                <Link to="#" className="facebook"><i className="bi bi-facebook"></i></Link>
                                <Link to="#" className="instagram"><i className="bi bi-instagram"></i></Link>
                                <Link to="#" className="linkedin"><i className="bi bi-linkedin"></i></Link>
                            </div>
                            {/* <img src='/assets/img/logo.png' alt='logo' className='w-75 mt-0.25 '/> */}
                        </div>

                        <div className="col-lg-3 col-6 footer-links">
                            <h4>Cybersecurity Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">VAPT</Link> <i className="bi bi-chevron-right mx-2"></i> <Link to="/">GDPR COMPLIANCE</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">PCI-DSS</Link><i className="bi bi-chevron-right mx-2"></i> <Link to="/">HIPPA COMPLIANCE</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">CMMI Level 3 & 5</Link></li>
                                <li><i className="bi bi-chevron-right"></i><Link to="/">ISO 27701</Link><i className="bi bi-chevron-right mx-2"></i> <Link to="/">ISO 13485</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">PCI-DSS</Link> <i className="bi bi-chevron-right mx-2"></i> <Link to="/">ISO 50001</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">SERVER HARDENING</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="/">SOC 1/2/3 AUDIT</Link></li>
                                
                            </ul>
                        </div>

                        <div className="col-lg-3 col-6 footer-links">
                            <h4>Certificate Services</h4>
                            <ul>
                                <li><i className="bi bi-chevron-right"></i> <Link to="iso-9001">ISO 9001</Link><i className="bi bi-chevron-right"></i> <Link to="iso-22301">ISO 22301</Link> </li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="iso-14001">ISO 14001</Link> <i className="bi bi-chevron-right"></i> <Link to="iso-27001">ISO 27001</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="iso-45001">ISO 45001</Link> <i className="bi bi-chevron-right"></i> <Link to="iso-27701">ISO 27701</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="iso-20000">ISO 20000</Link> <i className="bi bi-chevron-right"></i> <Link to="iso-13485">ISO 13485</Link></li>
                                <li><i className="bi bi-chevron-right"></i> <Link to="iso-22000">ISO  22000</Link> <i className="bi bi-chevron-right"></i> <Link to="iso-50001">ISO 50001</Link></li>
                                
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contact Us</h4>
                            <p>
                                Rubangura, floor 2 room 203 <br />
                                KN 2 St, Kigali<br />
                                Kigali Rwanda <br /><br />
                                <Link to="#"  onClick={() => window.open('tel:+250785395473')}>
                                    <span style={{marginRight: '7px'}}><i className='bi bi-telephone'></i></span>
                                    +250 783875133
                                </Link><br />

                               <Link to="#" onClick={() => window.open('mailto:info@universal-cert.com')}>
                                   <span style={{marginRight: '7px'}}><i className="bi bi-envelope"></i></span>
                                    Email us
                                </Link>
                            </p>
                        </div>

                    </div>
                </div>
            </div>            

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Universal Cert.</span></strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    )
}

export default Footer