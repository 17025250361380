import React from 'react';

const About = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content gap-2">
                        <a href='/' className='' onClick={()=>window.open('https://drive.google.com/file/d/1Ic9ov4ZPFP7asmvlWJ-B-8AkwswRqdgO/view?usp=drive_link')} target='blank'><h3>Application Form </h3></a>
                        <a href='/' className='' onClick={()=>window.open('https://drive.google.com/file/d/1YPil1M0L8CijT4fuDq2TbsoyvOQzZom-/view?usp=drive_link')} target='blank'><h3>Customer Satisfaction</h3></a>
                        {/* <a href='/' className='' onClick={()=>window.open('https://drive.google.com/file/d/1H94n79M4h6FNRUwoHuEza0PXxtFo5z9p/view?usp=drive_link')} target='blank'><h3>Procedures for Complaints / Appeals / Feedback</h3></a> */}
                        <a href='/' className='' onClick={()=>window.open('https://drive.google.com/file/d/1nczX0mv2ABcvgURcciB6OJwsW1rBINfr/view?usp=drive_link')} target='blank'><h3>Terms of Use</h3></a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default About