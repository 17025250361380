import React from 'react';

const Cert2 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO 14001:2015 Certification (Environmental Management System)</h2>
                            <p>ISO 14001:2015 helps an organization achieve the intended outcomes of its environmental 
                                management system, which provides value for the environment,
                                 the organization itself and interested parties.</p>
                
                            <h2> Its aims to integrate the organization’s EMS into the core organizational strategy. The key changes in this standard are:</h2> 
                                <ul>
                                    <li>A greater commitment from leadership.</li>
                                    <li>An increased alignment with strategic direction.</li>
                                    <li>Greater protection for the environment, with a focus on proactive initiatives.</li>
                                    <li>More effective communication, driven through a communications strategy.</li>
                                    <li>Life-cycle thinking, considering each stage of a product or service, from development to end-of-life.</li>
                                </ul>
                               
                            
                            <h2>
                            Advantage of ISO 14001 Certification
                            </h2>
                            <ul>
                            <li>Demonstrate compliance with current and future statutory and regulatory requirements.</li>
                            <li>Increase leadership involvement and engagement of employees.</li>
                            <li>Improve company reputation and the confidence of stakeholders through strategic communication.</li>
                            <li>Achieve strategic business aims by incorporating environmental issues into business management.</li>
                            <li>Provide a competitive and financial advantage through improved efficiencies and reduced costs.</li>
                            <li>Encourage better environmental performance of suppliers by integrating them into the organization’s business systems.</li>
                                </ul>
                                <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert2