import React from 'react';

const Cert9 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO 13485:2016 Certification (Medical Device Quality Management System)</h2>
                            <p></p>
                            <h2>What is ISO 13485:2016 ?</h2>
                            <p>ISO 13485 or Medical Device Quality Management System (MDQMS) is designed to be used by 
                                organizations involved in the design, production, installation and servicing of 
                                medical devices and related services. It can also be used by internal and external parties, 
                                such as certification bodies, to help them with their auditing processes. If you looking for ISO 13485 certification. 
                                You are at right place. We are best ISO Consultancy provider for 13485:2016</p>
                            
                            <h2>
                            5 Key Benefits of ISO 13485:2016 Certification
                            </h2>
                            <ol>
                                <li>Improve the company’s credibility and image.</li>

                                <li> Improve your process.</li>

                                <li> Improve decision-making.</li>

                                <li> Create a culture of continual improvement</li>

                                <li> Better employee engagement.</li>
                                </ol>

                          <h2>ISO 13485:2016 Requirements</h2>   
                                    <p><b>Clause 1:</b> Scope– In this clause we need to define our physical or service boundary to implement MDQMS.</p>

                                    <p><b>Clause 2:</b> Normative References– this section talk about 
                                    what are normative reference used in this standard. For Example: ISO9000:2015-Fundamentals and vocabulary.</p>

                                    <p><b>Clause 3:</b> Terms & Definitions-this clause describe about terms and 
                                    definitions given in ISO 9000:2015.</p>

                                    <p><b>Clause 4:</b> Quality Management System – This clause talks about general QMS requirements, as well as the documentation requirements of the standard. It includes the requirements for the Quality Manual, Control of Documents, and Control of Records, all of which are required documents in the QMS.</p>

                                    <p><b>Clause 5:</b> Management Responsibility – The management responsibility requirements cover the need for top management to be instrumental in the implementation and maintenance of the QMS. Along with planning for the QMS, 
                                    there is a need for top management to be involved in the ongoing review of the system to ensure customer satisfaction and improvement.</p>

                                    <p><b>Clause 6:</b> Resource Management – The section on management of resources is short, but covers the necessity to control all resources, 
                                    including human resources, buildings, and infrastructure and the working environment.</p>

                                    <p><b>Clause 7:</b> Product Realization – The product requirements deal with all aspects of the planning and creation of the product or service. This section includes requirements on planning, product requirements review, design, purchasing, creating the product or service, and controlling the equipment used to monitor and measure the product or service. ISO 13485 allows for requirements in the section to be excluded if they are not applicable to the company (such as a company that does not design products or services).</p>

                                    <p><b>Clause 8:</b> Measurement, Analysis and Improvement – This last section includes the requirements needed to make sure that you can monitor whether your QMS is functioning well. It includes assessing customer satisfaction, internal audits, monitoring products and processes, dealing with non-conforming product, and corrective and preventive actions.  </p> 
                                <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert9