import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {
    const [showDrop, setShowDrop] = useState(false)
    const [showDrop2, setShowDrop2] = useState(false);

    useEffect(() => {
        var navLinks = Array.from(document.getElementsByClassName('nav-link'));
        
        navLinks.forEach(element => {
            element.addEventListener('click', () => {
                const navbar = document.getElementById('navbar');
                if (navbar.classList.contains('navbar-mobile')) {
                    document.getElementById("sdbartog").click();
                }
            });
        });
    }, []);

    return (
        <header id="header" className="header fixed-top">
            <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                <Link to="/" title='Universal-cert.' className="logo d-flex align-items-center">
                    <img src="/assets/img/logo.png" alt="Universal-cert." style={{ paddingBottom: '' }} />
                    <div className='d-flex flex-column mt-3'>
                    <span id='hdbrand' style={{ whiteSpace: 'nowrap' }}>Universal Cert.<br></br></span>
                    <span className='p-3 mt-3 '>By UHDSS</span>
                    </div>
                   
                </Link>
                <nav id="navbar" className="navbar">
                    <ul>
                        <li><NavLink className="nav-link scrollto" to="/">Home</NavLink></li>
                        <div className="dropdown">
                            <button className="btn btn-link font-weight-bold text-decoration-none dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>setShowDrop2(!showDrop2)}>
                               About
                            </button>
                            {showDrop2 &&
                            (
                                <ul className="dropdown-menu d-flex flex-column" aria-labelledby="dropdownMenuButton1">
                                <li> <Link className='dropdown-item nav-link' to="about">General Information</Link></li>
                                    {/* <li> <Link className="dropdown-item nav-link" to="*">Verify Client</Link></li> */}
                                    
                            </ul>
                            )}
                            
                        </div>
                        
                        <div className="dropdown">
                            <button className="btn btn-link font-weight-bold text-decoration-none dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>setShowDrop(!showDrop)}>
                               Services
                            </button>
                            {showDrop &&
                            (
                                <ul className="dropdown-menu d-flex flex-column" aria-labelledby="dropdownMenuButton1">
                                <li> <Link className='dropdown-item nav-link' to="iso-9001">ISO 9001 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-14001">ISO 14001 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-45001">ISO 45001 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-20000">ISO 20000 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-22000">ISO 22000 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-27001">ISO 27001 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-27701">ISO 27701 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-22301">ISO 22301 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-13485">ISO 13485 Certification</Link></li>
                                    <li> <Link className="dropdown-item nav-link" to="iso-50001">ISO 50001 Certification</Link></li>
                            </ul>
                            )}
                            
                        </div>
                        <li><NavLink className="nav-link scrollto" to="/contact-us">Contact Us</NavLink></li>

                       
                       
                    </ul>
                    <i id='sdbartog' className="bi bi-list mobile-nav-toggle"></i>
                </nav>

            </div>
        </header>
    );
}

export default Navbar;