import React from 'react';

const Cert10 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO 50001:2018 Certification (Energy Management System)</h2>
                            <p></p>
                            <h2>What is ISO 50001:2018 ?</h2>
                                <p><b>ISO 50001:2018 (EnMS)</b> is an International standard for organizations 
                                    that want to manage and reduce energy use. EnMS Certification motivate companies to develop an energy policy, establish energy goals, 
                                    design energy policy and measure the effectiveness of EnMS in the organization.</p>
                            
                            <h2>
                            5 Key Benefits of ISO 50001:2018 Certification
                            </h2>
                            <ol>
                                <li> ISO 50001 create awareness for saving of energy.</li>

                                <li> Help in reducing energy cost.</li>

                                <li> Improve overall energy consumption.</li>

                                <li> Reduce unnecessary energy expenses.</li>

                                <li> Improves market reputation.</li>
                                </ol>
                                <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert10