import React from 'react';

const Cert6 = () => {
    return (
        <section id="about" className="about">

            <div className="container" data-aos="fade-up">
                <div className="row gx-0">

                    <div data-aos="fade-up" data-aos-delay="200">
                        <div style={{maxWidth: "900px", margin: "auto"}} className="d-flex flex-column content">
                            <h2>ISO 27001:2022 Certification (Information Security Management System)</h2>
                            <p>Universal Cert is a pioneer certification body for ISO 27001:2022 (ISMS). Also provide the services like documentation, audit, training, consultancy, certification & VAPT Services.</p>
                                
                                
                                    <h2>What is ISO 27001:2022 ?</h2>
                            
                            <p> <b>ISO/IEC 27001 </b>  is the international standard for providing requirements for an information security management system (ISMS), 
                            there are more than a dozen standards in the ISO/IEC 27000 family.
                            </p>
                            
                            <h2>
                            6 Key Benefits of ISO 27001:2022 Certification
                            </h2>
                            <ol>
                            <li>Gain business in competitive edge.</li>

                            <li> Avoid data breaches and legal penalties.</li>

                            <li> Improvement in work process (More Secure).</li>

                            <li> Fully compliance with information security regulations.</li>

                            <li> Shows your commitment to secure work environment.</li>

                            <li> Reduce the need of frequent audits in organization.</li>
                                </ol>
                                <button className='btn-primary'> Let's Begin your journey
                                        Be ISO Certified !!! Start Now</button>
                        </div>
                        
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Cert6